import axios from '../api';
import { IContactInfo } from '../types/accountSettings.types';
import { IProfileSettings } from '../types/profileSetting.types';

export const likeLogo = async ({
  logo_id,
  state,
}: {
  logo_id: number;
  state: number;
}) => {
  try {
    const response = await axios.post('/api/logos/like', {
      logo_id,
      state,
    });
    return response.status < 300;
  } catch (error) {
    return false;
  }
};

export const getLiked = async (page: number, page_size: number, designer_id?: number) => {
  try {
    const response = await axios.get(
      '/api/designers/likes?page_size=' +
        page_size +
        '&page=' +
        page +
        (designer_id ? '&designer_id=' + designer_id : ''),
      {}
    );
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getActivityFeed = async ({
  page_size,
  page,
}: {
  page_size: number;
  page: number;
}) => {
  try {
    const response = await axios.get(
      '/api/designers/activity?per_page=' + page_size + '&page=' + page,
      {}
    );
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getShortActivityFeed = async () => {
  try {
    const response = await axios.get('/api/designers/activity?short=1', {});
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getProfileInfo = async (designerId?: number) => {
  try {
    const response = await axios.get('/api/designers/profile', {
      params: { designer_id: designerId },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getProfileInfoBySlug = async (slug: string) => {
  try {
    const response = await axios.get('/api/designers/profile', {
      params: { folder_url: slug },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const updateProfileInfo = async (profileInfo: IProfileSettings) => {
  try {
    const response = await axios.put('/api/designers/profile', profileInfo);
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getProfileLogos = async (designerId?: number) => {
  try {
    const response = await axios.get('/api/designers/profile/logos', {
      params: { designer_id: designerId },
    });
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const updateProfileLogos = async (logos: any[]) => {
  try {
    const response = await axios.put('/api/designers/profile/logos', logos);
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getCheckoutInfo = async () => {
  try {
    const response = await axios.get('/api/designers/checkout-info');
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getContactInfo = async () => {
  try {
    const response = await axios.get('/api/designers/contact-info');
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const updateContactInfo = async (contactInfo: IContactInfo) => {
  try {
    const response = await axios.put('/api/designers/contact-info', contactInfo);
    return response.status < 300;
  } catch (error) {
    return false;
  }
};

export const followDesigner = async (designer_id: string, state: number) => {
  try {
    const response = await axios.post('/api/designers/follow', {
      designer_id: designer_id,
      state: state,
    });
    return response.status < 300;
  } catch (error) {
    return false;
  }
};

export const getDesignerAwards = async (
  designer_id: number | string,
  book_slug?: string
) => {
  try {
    const response = await axios.get('/api/designers/awards', {
      params: { designer_id, book_slug },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getDesignerAwardsBySlug = async (slug: string, book_slug?: string) => {
  try {
    const response = await axios.get('/api/designers/awards', {
      params: { folder_url: slug, book_slug },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getMembership = async () => {
  try {
    const response = await axios.get('/api/designers/membership');
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getAvatar = async () => {
  try {
    const response = await axios.get('/api/designers/avatar');
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getFollowers = async (
  page: number,
  page_size: number,
  designer_id?: number
) => {
  try {
    const response = await axios.get(
      `/api/designers/followers?designer_id=${designer_id}&page=${page}&page_size=${page_size}`,
      {
        params: { designer_id },
      }
    );
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const getFollowings = async (
  page: number,
  page_size: number,
  designer_id?: number
) => {
  try {
    const response = await axios.get(
      `/api/designers/following?designer_id=${designer_id}&page=${page}&page_size=${page_size}`,
      {
        params: { designer_id },
      }
    );
    if (response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const cancelSubscription = async (subscriptionId: number) => {
  try {
    const response = await axios.post('/api/subscriptions/cancel', { subscriptionId });
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      console.error('Error canceling subscription:', error.message);
      throw { error: error.message || 'An unexpected error occurred' };
    } else {
      console.error('Unknown error:', error);
      throw { error: 'An unexpected error occurred' };
    }
  }
};
