import React, { useEffect, useState } from 'react';
import { cancelSubscription, getMembership } from '../../../../../services/designer';
import { IMembership } from '../../../../../types/accountSettings.types';
import { getDate } from '../../../../../utils/functions';
import { Link } from 'react-router-dom';
import Skeleton from '../../../../atoms/Skeleton';
import useAuth from '../../../../../hooks/useAuth';

const Membership = () => {
  const {
    subscriptionStatus: { subscriptionActive, subscriptionId, nextBillingTime },
    checkAuth,
  } = useAuth();
  const [membershipInfo, setMembershipInfo] = useState<IMembership>();
  const [loading, setLoading] = useState(false);

  const getMessage = () => {
    if (subscriptionActive && subscriptionId) {
      return (
        <>
          Your membership is set to automatically renew.
          <br />
          Your next automatic payment is scheduled for{' '}
          <strong>{getDate(nextBillingTime || '', 'long')}</strong>.
        </>
      );
    } else {
      return (
        <>
          Your membership is not set to automatically renew.
          Your membership expires on{' '}
          <strong>{getDate(membershipInfo?.member_expire || '', 'long')}</strong>.
        </>
      );
    }
  };
  

  const handleCancel = async () => {
    setLoading(true);
    try {
      await cancelSubscription(subscriptionId as number);
      await checkAuth();
    } catch (error) {
      console.error('Error canceling subscription:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getMembership().then(data => {
      if (data) {
        setMembershipInfo(data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <section>
      <div className="content_outer">
        {loading ? (
          <Skeleton width={'100%'} height={20} />
        ) : (
          <div id="membership">
            <p>{getMessage()}</p>
            <div className="button_outer">
              {subscriptionActive && subscriptionId ? (
                <button className="renew_button" onClick={handleCancel}>
                  Cancel Subscription
                </button>
              ) : (
                <Link className="renew_button" to="/checkout">
                  Subscribe
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Membership;
