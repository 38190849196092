import React, { startTransition, useEffect, useMemo, useState } from 'react';
import Layout from '../components/molecules/Layout';
import clsx from 'clsx';
import { PayPalButtons } from '@paypal/react-paypal-js';
import axios from '../api';
import { getDate } from '../utils/functions';
import { IJoin } from '../types/join.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCheckoutInfo } from '../services/designer';
import useAuth from '../hooks/useAuth';
import useToast from '../hooks/useToast';
import { ICheckout } from '../types/checkout.types';

const Checkout = () => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const { 
    subscriptionStatus: { subscriptionActive },
    isAuthenticated, checkAuth } = useAuth();

  // Get userData from the previous page's state
  const [joinData, setJoinData] = useState<ICheckout>(location.state?.joinData);
  const [affiliate, setAffiliate] = useState<string>(location.state?.affiliate);
  const [giftCardCode, setGiftCardCode] = useState('');
  const [giftCardError, setGiftCardError] = useState('');
  const [isMonthly, setIsMonthly] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('paypal');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [monthExpDate, setMonthExpDate] = useState<Date>(new Date());
  const [yearExpDate, setYearExpDate] = useState<Date>(new Date());

  useEffect(() => {
    if (isAuthenticated) {
      if (subscriptionActive) {
        navigate('/account/membership');
      }
      getCheckoutInfo().then(userInfo => {
        if (userInfo) {
          setJoinData(prevData => ({
            ...prevData,
            ...userInfo,
          }));

          setRenewDates(userInfo?.member_expire);
        } else {
          toast.addToast({
            type: 'error',
            message: 'Error getting checkout information.',
          });
        }
      });
    } else if (joinData) {
      setRenewDates();
    } else {
      navigate('/join');
    }
  }, []);

  const setRenewDates = (memberExpire: string | null = null) => {
    let startDate = memberExpire ? new Date(memberExpire) : new Date();
    if (!startDate || startDate.toString() === 'Invalid Date') {
      startDate = new Date();
    }
    setStartDate(startDate);

    const monthAhead = new Date(startDate);
    monthAhead.setMonth(monthAhead.getMonth() + 1);
    setMonthExpDate(monthAhead);

    const yearAhead = new Date(startDate);
    yearAhead.setFullYear(yearAhead.getFullYear() + 1);
    setYearExpDate(yearAhead);
  };

  const setIsMonthlyMembership = (isMonthly: boolean) => {
    setIsMonthly(isMonthly);
  };

  const handlePaymentMethodChange = (method: string) => {
    setSelectedPaymentMethod(method);
  };

  const createSubscription = (data: any, actions: any) => {
    const planId = isMonthly
      ? process.env.REACT_APP_PAYPAL_MONTHLY_PLAN_ID
      : process.env.REACT_APP_PAYPAL_YEARLY_PLAN_ID;
    return actions.subscription
      .create({
        plan_id: planId,
        start_time: (startDate >= new Date()) ? startDate.toISOString() : null,
      })
      .then((orderId: string) => {
        return orderId;
      });
  };

  const onApprove = (data: any, actions: any) => {
    return axios
      .post('/api/register/paypal_approved', {
        orderID: data.orderID,
        subscriptionID: data.subscriptionID,
        user: joinData,
        isMonthly,
        isNew: !isAuthenticated,
        affiliate: affiliate,
      })
      .then(response => {
        if (response.status === 200) {
          if (isAuthenticated) {
            checkAuth().then(() => {
              navigate('/login');
            });
          } else {
            navigate('/login');
          }
        } else {
          alert(
            'Transaction completed but registration failed. Please contact LogoLounge support.'
          );
        }
      })
      .catch(error => {
        console.error('Error during PayPal order approval:', error);
        alert('An error occurred during the transaction. Please try again.');
      });
  };

  const handleGiftCardSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    axios
      .post('/api/register/giftcard', {
        gift_card_code: giftCardCode,
        user: joinData,
        isMonthly,
        isNew: !isAuthenticated,
        affiliate: affiliate,
      })
      .then(response => {
        const responseData = response.data;
        if (responseData.success) {
          window.location.href = '/login';
        } else {
          setGiftCardError(responseData.error);
        }
      })
      .catch(error => {
        console.error(error.message);
        setGiftCardError('An error occurred during the transaction. Please try again.');
      });
  };

  const getBeginDateMessage = useMemo(() => {
    return startDate > new Date()
      ? `Begins when your membership expires on ${getDate(startDate.toString(), 'long')}`
      : `Begins on ${getDate(new Date().toString(), 'long')}`;
  }, [startDate]);

  const getRenewalMessage = useMemo(() => {
    if (selectedPaymentMethod === 'paypal') {
      return `Automatically Renews ${getDate(isMonthly ? monthExpDate.toString() : yearExpDate.toString(), 'long')}`;
    }
    return null;
  }, [selectedPaymentMethod, isMonthly, monthExpDate, yearExpDate]);

  return (
    <Layout className={clsx('checkout')}>
      <main id="content">
        <article>
          <div className="content_outer has_sticky_left">
            <div className="sticky_content account_info">
              <h6>Your Account</h6>
              <ul>
                <li>
                  {joinData?.first_name} {joinData?.last_name}
                </li>
                {joinData?.first_name + ' ' + joinData?.last_name !==
                  joinData?.company && <li>{joinData?.company}</li>}
                {joinData?.email && <li>{joinData?.email}</li>}
                {joinData?.phone && <li>{joinData?.phone}</li>}
                {joinData?.address1 && (
                  <li>
                    {joinData?.address1} {joinData?.address2}
                    <br />
                    {joinData?.city}, {joinData?.state} {joinData?.zip}
                    <br />
                    {joinData?.country}
                  </li>
                )}
              </ul>
            </div>

            <div className="non_sticky_content">
              <h1>{isMonthly ? 'Renew' : 'Checkout'}</h1>

              <input
                type="radio"
                id="yearly_plan"
                name="subscription"
                onChange={() => setIsMonthlyMembership(false)}
                defaultChecked
              />
              <input
                type="radio"
                id="monthly_plan"
                name="subscription"
                onChange={() => setIsMonthlyMembership(true)}
              />

              <section className="content_yearly_plan hide_radio">
                <label htmlFor="yearly_plan" className="group">
                  <div className="info">
                    Annual Membership
                    <div className="amount">$100.00</div>
                  </div>
                  <div className="expire_date">
                    {getBeginDateMessage}
                    {getRenewalMessage && <div>{getRenewalMessage}</div>}
                  </div>
                </label>
              </section>
              {/*
              <section>
                <label htmlFor="monthly_plan" className="group">
                  <div className="info">
                    Monthly Membership
                    <div className="amount">$10.00</div>
                  </div>
                  <div className="expire_date">
                    {startDate > new Date()
                      ? `Begins when your  membership expires on ${getDate(startDate.toString(), 'long')}`
                      : `Begins on ${getDate((new Date()).toString(), 'long')}`}
                    Automatically Renews {getDate(monthExpDate.toString(), 'long')}
                  </div>
                </label>
              </section>
              */}
              <br />
              <br />
              <br />
              <br />
              <input
                type="radio"
                id="paypal_method"
                name="payment_method"
                checked={selectedPaymentMethod === 'paypal'}
                onChange={() => handlePaymentMethodChange('paypal')}
              />
              <input
                type="radio"
                id="gift_card_method"
                name="payment_method"
                checked={selectedPaymentMethod === 'gift_card'}
                onChange={() => handlePaymentMethodChange('gift_card')}
              />

              <section>
                <label htmlFor="paypal_method">Checkout with PayPal or Credit Card</label>
                <div className="paypal_buttons">
                  <PayPalButtons
                    className="button_outer"
                    createSubscription={createSubscription}
                    onApprove={onApprove}
                    style={{ layout: 'vertical' }}
                    forceReRender={[isMonthly, joinData]}
                  />
                </div>
              </section>

              <section>
                <label htmlFor="gift_card_method">Checkout with Gift Card</label>
                <form
                  className="gift_card_form"
                  onSubmit={handleGiftCardSubmit}
                  method="post"
                  data-action="block-double-submit"
                  data-action-value="Validating giftcard..."
                >
                  <div className="form-group">
                    <label className="control-label">Gift Card Number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="GiftCard"
                      value={giftCardCode}
                      onChange={e => setGiftCardCode(e.target.value)}
                      required={true}
                    />
                  </div>
                  {giftCardError && (
                    <div className="alert alert-danger">{giftCardError}</div>
                  )}

                  <div className="button_outer">
                    <button className="btn btn-primary" style={{ cursor: 'pointer' }}>
                      Process Gift Card
                    </button>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Checkout;
