import * as yup from 'yup';

export const passwordRequirements = `Password must be between 8 and 20 characters long, containing: one uppercase letter, one lowercase letter, one number, and one special character.`;
export const phoneRegExp = /^$|^(\+\d{1,2}\s?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const joinSchema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, passwordRequirements)
    .max(20, passwordRequirements)
    .matches(/(?=.*[a-z])/, passwordRequirements)
    .matches(/(?=.*[A-Z])/, passwordRequirements)
    .matches(/(?=.*[0-9])/, passwordRequirements)
    .matches(/(?=.*[!@#$%^&*])/, passwordRequirements),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  company: yup.string().required('Company is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup
    .string()
    // Temporarily disabling phone input validation until the frontend automatically applies the required formatting
    // .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone is required'),
  address1: yup.string().required('Address is required'),
  address2: yup.string().nullable().notRequired(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('ZIP is required'),
  country: yup.string().required('Country is required'),
  terms: yup
    .boolean()
    .oneOf([true], 'Terms and Conditions is Required')
    .required('Terms and Conditions is Required'),
});
