import React, { ReactNode, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  checkAuthStatus,
  login as loginapi,
  logout as logoutapi,
  updatePassword as updateapi,
} from '../services/auth';
import { AuthContext, SubscriptionStatusProps } from '../context/AuthContext';
import { IAuthStatus } from '../types/accountSettings.types';
import useToast from '../hooks/useToast';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [memberType, setMemberType] = useState<string>('');
  const [designerId, setDesignerId] = useState<number>(0);
  const [username, setUsername] = useState('Anonymous');
  const [avatar, setAvatar] = useState('');
  const [slug, setSlug] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionStatusProps>(
    {} as SubscriptionStatusProps
  );

  // Update local storage with auth status
  const updateLocalStorage = (authStatus: boolean) => {
    localStorage.setItem('isAuthenticated', authStatus.toString());
  };

  // Handle storage event to keep auth status in sync across tabs
  const handleStorageEvent = (event: StorageEvent) => {
    if (event.key === 'isAuthenticated') {
      // Do nothing if the value hasn't actually changed
      if (event.newValue === event.oldValue) return;

      checkAuth();

      const loggedStatus = event.newValue === 'true';
      // Navigate to login if logged out and not already on the login page
      // Stay on page if path is profile
      if (
        !loggedStatus &&
        location.pathname !== '/login' &&
        location.pathname !== '/designers'
      ) {
        navigate('/login');
      }
      // Navigate to root if logged in and currently on the login page
      else if (loggedStatus && location.pathname === '/login') {
        navigate('/');
      }
    }
  };

  const setAuthStatus = async (authStatus: IAuthStatus | null) => {
    setIsAuthenticated(authStatus?.isAuthenticated || false);
    setIsActive(authStatus?.isActive || false);
    setMemberType(authStatus?.memberType || 'Anonymous');
    setDesignerId(authStatus?.designerId || 0);
    setUsername(authStatus?.username || 'Anonymous');
    setAvatar(authStatus?.avatar || '');
    setSlug(authStatus?.slug || '');
    updateLocalStorage(authStatus?.isAuthenticated || false);
    setSubscriptionStatus({
      subscriptionActive: authStatus?.subscriptionActive || false,
      subscriptionId: authStatus?.subscriptionId || null,
      nextBillingTime: authStatus?.nextBillingTime,
    });
  };

  const checkAuth = async () => {
    const authStatus = await checkAuthStatus();
    if (authStatus) {
      setAuthStatus(authStatus);
    } else {
      toast.addToast({
        type: 'error',
        message: 'Error: Could not connect to authorization server.',
      });
    }
  };

  const login = async (username: string, password: string): Promise<IAuthStatus> => {
    const authStatus = await loginapi(username, password);
    if (authStatus) {
      setAuthStatus(authStatus);
      if (!authStatus.isAuthenticated) {
        toast.addToast({
          type: 'error',
          message: 'Username or Password Incorrect',
        });
      }
    } else {
      toast.addToast({
        type: 'error',
        message: 'Error: Could not connect to authorization server.',
      });
    }
    return authStatus;
  };

  const logout = async () => {
    await logoutapi().then(response => {
      if (response) {
        setAuthStatus(null);
      } else {
        toast.addToast({
          type: 'error',
          message: 'Error: Could not connect to authorization server.',
        });
      }
      return response;
    });
  };

  const updatePassword = async (
    old_password: string,
    new_password: string
  ): Promise<string> => {
    return updateapi(old_password, new_password).then(async (response: string) => {
      if (response) {
        if (response === 'Invalid credentials') {
          toast.addToast({
            type: 'error',
            message: 'Current password is incorrect.',
          });
        } else {
          toast.addToast({
            type: 'success',
            message: 'Password updated successfully.',
          });
          await login(username, new_password);
        }
        return response;
      }
      toast.addToast({
        type: 'error',
        message: 'Error: Could not update password.',
      });
      return '';
    });
  };

  useEffect(() => {
    const initializeAuth = async () => {
      await checkAuth();
      setIsLoading(false);
    };

    initializeAuth();

    window.addEventListener('storage', handleStorageEvent);
    // Cleanup
    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        isAuthenticated,
        isActive,
        memberType,
        designerId,
        username,
        avatar,
        slug,
        checkAuth,
        login,
        logout,
        updatePassword,
        setAvatar,
        subscriptionStatus,
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
