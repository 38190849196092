import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Tabs from '../components/atoms/Tabs';
import TabItem from '../components/atoms/TabItem';
import TabPanel from '../components/atoms/TabPanel';
import FreshContent from '../components/features/pages/FreshContent';
import NewsContent from '../components/features/pages/NewsContent';
import FollowingContent from '../components/features/pages/FollowingContent';
import { getFollowing, getFresh } from '../services/logo';
import { ILogo } from '../types/logos.types';
import useToast from '../hooks/useToast';
import BannerImage from '../assets/images/banner/book_15_promo.png';

const Explore = () => {
  const toast = useToast();
  const sections = ["fresh", "following", "news"]
  const { section, pageParam } = useParams<string>();
  const page_size = 50;
  const [page, setPage] = useState(parseInt(pageParam || '1'));
  const [tab, setTab] = useState(sections.indexOf(section || 'fresh'));
  const [count, setCount] = useState(0);
  const [logos, setLogos] = useState<ILogo[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  const handleTabChange = (newTab: number) => {
    const div = document.querySelector('#root-outer-div');
    div?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    navigate('/' + sections[newTab]);
  };

  useEffect(() => {
    setTab(sections.indexOf(section || 'fresh'));
  }, [section]);

  useEffect(() => {
    setPage(1);
    setLogos([]);
  }, [tab]);

  const actionCallback = (logoId: number, action: string, params: any) => {
    if (action === 'like') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_liked: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    } else if (action === 'save') {
      const newLogos = logos.map((logo: ILogo) => {
        if (logo.logo_id === logoId) {
          return {
            ...logo,
            is_collected: params.value,
          };
        } else {
          return logo;
        }
      });
      setLogos(newLogos);
    }
  };

  const refreshLogos = () => {
    setIsLoading(true);
    if (tab === 0) {
      getFresh({ page, page_size })
        .then(data => {
          if(data){
            setLogos(prevLogos => [...prevLogos, ...data.data]);
            setCount(data.count);
          } else {
            setLogos([]);
            setCount(0);
            toast.addToast({
              type: 'error',
              message: 'Error loading fresh logos.',
            });
          }
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
        });
    } else if (tab === 1) {
      getFollowing({ page, page_size })
        .then(data => {
          if(data){
            setLogos(prevLogos => [...prevLogos, ...data.data]);
            setCount(data.count);
          } else {
            setLogos([]);
            setCount(0);
            toast.addToast({
              type: 'error',
              message: 'Error loading following feed.',
            });
          }
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          console.error('Error during refreshLogos fetch:', error);
        });
    }
  };

  useEffect(() => {
    document.title = 'Explore - LogoLounge';
    refreshLogos();
    // eslint-disable-next-line
  }, [page, tab]);

  return (
    <Layout className="explore">
      <main id="content" className="has_tabs tab_count-3">
        <div className="promo_banner_outer">
          <Link className="promo_banner" to="https://www.logolounge.com/compete">
            <img src={BannerImage} alt="LogoLounge Book 15 Compete" />
          </Link>
        </div>
        <Tabs value={tab} onChange={handleTabChange}>
          <TabItem label="Fresh" />
          <TabItem label="Following" />
          <TabItem label="News" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <div className="results_count_top">
            {!isLoading &&
              <>
                {count.toLocaleString()} logos
              </>
            }
          </div>
          <FreshContent
            logos={logos}
            actionCallback={actionCallback}
            count={count}
            page={page}
            maxPage={Math.ceil(count / page_size)}
            setPage={setPage}
            isLoading={isLoading}
          />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <FollowingContent
            logos={logos}
            actionCallback={actionCallback}
            count={count}
            page={page}
            maxPage={Math.ceil(count / page_size)}
            setPage={setPage}
            isLoading={isLoading}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <NewsContent />
        </TabPanel>
      </main>
    </Layout>
  );
};

export default Explore;
