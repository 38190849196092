import React, { useEffect, useState } from 'react';
import Layout from '../components/molecules/Layout';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { joinSchema } from '../schemas/join.schema';
import { IJoin } from '../types/join.types';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import axios from '../api';
import useAuth from '../hooks/useAuth';
import useToast from '../hooks/useToast';
import { countries } from '../utils/functions';

// Extend IJoin locally to include the notes field
interface IJoinWithNotes extends IJoin {
  notes?: string;
}

const Join = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const toast = useToast();
  const [terms, setTerms] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [affiliate, setAffiliate] = useState<string | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    control,
  } = useForm<IJoin>({ resolver: yupResolver(joinSchema) });

  // Set userData with Partial<IJoinWithNotes> to allow empty initial state
  const [userData, setUserData] = useState<Partial<IJoinWithNotes>>({});

  useEffect(() => {
    document.title = 'Join LogoLounge - Logo Design Inspiration & Competition - Work';

    // Extract the 'aff' parameter from the URL
    const params = new URLSearchParams(window.location.search);
    const aff = params.get('aff');
    if (aff) setAffiliate(aff);

    // If the user is already authenticated, redirect to checkout
    if (isAuthenticated) navigate('/checkout');
  }, [isAuthenticated, navigate]);

  const handleContinue = async () => {
    const isValid = await trigger(['username', 'password', 'terms']);
    if (isValid) return setShowForm(true);
    setShowForm(false);
  };

  const handleChange = (
    onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Only keep numbers, spaces, dashes, plus signs, and parentheses
    const cleanedValue = event.target.value.replace(/[^\d\s\-+()]/g, '');

    // Limit the total length to 20 characters to prevent extremely long inputs
    const truncatedValue = cleanedValue.slice(0, 20);

    event.target.value = truncatedValue;
    onChange(event);
  };

  const handleJoin = (data: IJoin) => {
    const userDataWithNotes: IJoinWithNotes = { ...data };
    if (affiliate) {
      userDataWithNotes.notes = `aff=${affiliate}`;
    }

    const { terms, ...rest } = userDataWithNotes;

    axios
      .post('/api/register/validate', { user: rest, isNew: true })
      .then(() => {
        navigate('/checkout', {
          state: { joinData: rest, affiliate: affiliate },
        });
      })
      .catch(error => {
        const errorData = error?.response?.data;

        if (errorData?.errors && typeof errorData.errors === 'object') {
          Object.entries(errorData.errors).forEach(([field, msg]) => {
            toast.addToast({
              type: 'error',
              message: `${field}: ${msg}`,
            });
          });
        } else {
          const fallbackMessage =
            errorData?.error || error?.response?.statusText || 'Unknown error';

          toast.addToast({
            type: 'error',
            message: 'Invalid User Info: ' + fallbackMessage,
          });
        }

        console.error('Invalid User Info:', errorData);
      });
  };

  return (
    <Layout className="join has_right_background">
      <main id="content">
        <figure></figure>

        <article>
          <div className="content_outer">
            <h1>Join the fun.</h1>
            <form className="form-join" onSubmit={handleSubmit(handleJoin)}>
              <input type="hidden" name="step" value="1" />

              <fieldset>
                <legend>New Account</legend>

                <div className="form-group form-group--required">
                  <label className="control-label required">Username</label>
                  <input className="form-control" type="text" {...register('username')} />
                  {errors.username?.message && (
                    <span className="error_message">{errors.username?.message}</span>
                  )}
                </div>

                <div className="form-group">
                  <label className="control-label required">Password</label>
                  <div className="password-container">
                    <input
                      className="form-control input-password"
                      type={showPassword ? 'text' : 'password'}
                      maxLength={20}
                      {...register('password')}
                    />
                    <div
                      className="icon"
                      onClick={() => setShowPassword(prevShow => !prevShow)}
                    >
                      {showPassword ? <VscEye /> : <VscEyeClosed />}
                    </div>
                  </div>
                  <span className="error_message">{errors.password?.message}</span>
                </div>

                <div className="form-group checkbox_outer">
                  <input type="checkbox" id="terms" value="1" {...register('terms')} />

                  <label htmlFor="terms">
                    I agree to the{' '}
                    <a href="/terms" target="_blank">
                      Terms and Conditions
                    </a>
                    . <strong style={{ color: 'red' }}>*</strong>
                  </label>

                  {errors.terms?.message && (
                    <span className="error_message">{errors.terms.message}</span>
                  )}
                </div>
              </fieldset>

              <div className="button_outer">
                <p>
                  <a href="login">Already a member?</a>
                </p>

                <label className="button" onClick={handleContinue}>
                  Continue
                </label>
              </div>

              {showForm && (
                <>
                  <fieldset>
                    <legend>About You</legend>

                    <div className="form-group-wrap--name">
                      <div className="form-group">
                        <label className="control-label required">First Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('first_name')}
                        />
                        {errors.first_name?.message && (
                          <span className="error_message">
                            {errors.first_name?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label required">Last Name</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('last_name')}
                        />
                        {errors.last_name?.message && (
                          <span className="error_message">
                            {errors.last_name?.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label required">Company</label>
                      <input
                        className="form-control"
                        type="text"
                        {...register('company')}
                      />
                      {errors.company?.message && (
                        <span className="error_message">{errors.company?.message}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="control-label required">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        {...register('email')}
                      />
                      {errors.email?.message && (
                        <span className="error_message">{errors.email?.message}</span>
                      )}
                    </div>

                    <div className="form-group">
                      <label className="control-label required">Phone</label>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <input
                            className="form-control"
                            type="tel"
                            {...field}
                            onChange={event => handleChange(field.onChange, event)}
                            placeholder="Enter phone number"
                            maxLength={20}
                          />
                        )}
                      />
                      {errors.phone?.message && (
                        <span className="error_message">{errors.phone?.message}</span>
                      )}
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend>Your Whereabouts</legend>

                    <div className="form-group-wrap--street-suite">
                      <div className="form-group">
                        <label className="control-label required">Street Address</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address1')}
                        />
                        {errors.address1?.message && (
                          <span className="error_message">
                            {errors.address1?.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label">Suite</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('address2')}
                        />
                      </div>
                    </div>

                    <div className="form-group-wrap--city-state-zip">
                      <div className="form-group">
                        <label className="control-label required">City</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('city')}
                        />
                        {errors.city?.message && (
                          <span className="error_message">{errors.city?.message}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label required">State/Province</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('state')}
                        />
                        {errors.state?.message && (
                          <span className="error_message">{errors.state?.message}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="control-label required">Postal Code</label>
                        <input
                          className="form-control"
                          type="text"
                          {...register('zip')}
                        />
                        {errors.zip?.message && (
                          <span className="error_message">{errors.zip?.message}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label required">Country/Region</label>
                      <select className="form-control" {...register('country')}>
                        <option value="">Choose a country...</option>
                        {countries.map(country => (
                          <option key={country.value} value={country.value}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      {errors.country?.message && (
                        <span className="error_message">{errors.country?.message}</span>
                      )}
                      <p className="form-country-message">
                        If your country is not in the list, please contact{' '}
                        <a href="mailto:info@logolounge.com" target="_blank">
                          info@logolounge.com
                        </a>
                      </p>
                    </div>

                    <div className="button_outer">
                      <button type="submit">Create Account</button>
                    </div>
                  </fieldset>
                </>
              )}
            </form>
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default Join;
